import React from 'react';
import { Outlet } from "react-router-dom";
import MainHeader from "../Common/header";
import { MyDiv, PTag } from "../Common/Components";
import PatientSideBar from './sidebar';


function PatientTemplate() {
    return (
        <MyDiv className="UserPanel">
            <MainHeader />
            <MyDiv className="GeneralContainer">
                <MyDiv className="LeftSideContainer">
                    <PatientSideBar />
                </MyDiv>
                <MyDiv className="RightMainContainer">
                    <MyDiv className="Right-Side-Main-Container">
                        <Outlet />
                    </MyDiv>
                    <MyDiv className="footer-copyrights ">
                    <PTag className="text-center mt-15">{(new Date().getFullYear())} @ HealerBuddy. All rights reserved. If you need urgent help,<a href='tel:911'>call 911</a> or go to the hospital</PTag>
                    </MyDiv>
                </MyDiv>
            </MyDiv>
        </MyDiv>
    );
}

export default PatientTemplate;