import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux/store';
import { fetchrefreshTokenData } from './Redux/userSlice';
import { AuthProvider } from './context/AuthProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
const GOOGLE_CLIENT_ID_LOGIN = process.env.REACT_APP_GOOGLE_CLIENT_ID_LOGIN;

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
      <AuthProvider>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID_LOGIN}>
        <App/>
        </GoogleOAuthProvider>
        </AuthProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
