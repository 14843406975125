import swal from "sweetalert2";


 export const calculateAge=(dateOfBirth) => {
    // Parse the date of birth in the provided format
    const parts = dateOfBirth.split("-");
    const birthYear = parseInt(parts[0], 10);
    const birthMonth = parseInt(parts[1], 10) - 1; // Months are zero-based
    const birthDay = parseInt(parts[2].split("T")[0], 10);

    const birthDate = new Date(birthYear, birthMonth, birthDay);
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday hasn't occurred yet this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
      return age - 1; // Subtract 1 if the birthday hasn't occurred yet this year
    }

    return age;
  }


  export const customSuccessHandler = (successstring) => {
    return swal.fire({
      text: successstring,
      icon: "success",
      type: "success"
  });
  }

  export const customErrorHandler = (errorstring) => {
    return swal.fire({
      text: errorstring,
      icon: "error",
      type: "error"
  });
  }


  export const CustomCurrency = ({ value, currency, locale }) => {
    const formattedValue = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).format(value).replace('CA$', 'C$');

    return <span>{formattedValue}</span>;
};

 