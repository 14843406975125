import React, { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';
import useAuth from "../hooks/useAuth";
const PAYLOADKEY = process.env.REACT_APP_PAYLOAD_SECRET_KEY;


const RequireAuth = ({ role }) => {
  const user = useSelector(state => state.user);
  const location = useLocation();
  const { auth } = useAuth();
  const decryptData = (encryptedData, ivString) => {

    const iv = CryptoJS.enc.Hex.parse(ivString);

    // Decrypt the data using AES decryption
    const decrypted = CryptoJS.AES.decrypt(
      encryptedData,
      CryptoJS.enc.Utf8.parse(PAYLOADKEY),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    if(decrypted.toString(CryptoJS.enc.Utf8)){
      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)).mode;
    }
  };

  return (
    decryptData(user.mode, user.string) === role ? <Outlet /> :
      user?.token
        ? <Navigate to="/" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default RequireAuth;
