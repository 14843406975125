import React from 'react';
import { Outlet } from "react-router-dom";
import { MyDiv, PTag } from "./Common/Components";


function TemplateCore() {
    return (
        <div>
            <MyDiv className="GeneralContainer">
                <MyDiv className="RightMainContainer">
                    <MyDiv className="Right-Side-Main-Container">
                        <Outlet />
                    </MyDiv>
                    <MyDiv className="footer-copyrights ">
                        <PTag className="text-center mt-15">{(new Date().getFullYear())} @ HealerBuddy. All rights reserved</PTag>
                    </MyDiv>
                </MyDiv>
            </MyDiv>
        </div>
    );
}

export default TemplateCore;