// formSlice.js
import { createSlice } from '@reduxjs/toolkit';




const initialState = {
  field1: '',
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    reset: () => initialState,
  },
});



export const { reset } = formSlice.actions;
export default formSlice.reducer;
